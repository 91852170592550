import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AnswersAuditHistoryComponent,
  AuditHistoryComponent,
  BooleanComponent,
  BusinessUnitDetailsComponent,
  CodeComponent,
  ContactLookupComponent,
  DateComponent,
  DollarComponent,
  EmailComponent,
  ErrorModalComponent,
  FeinComponent,
  Float1Component,
  IconComponent,
  InstructionComponent,
  LoadingIndicatorComponent,
  ModalComponent,
  NotificationsComponent,
  NumericComponent,
  PhoneComponent,
  SectionTitleComponent,
  SsnComponent,
  StringComponent,
  TextComponent,
  ThreeStateComponent,
  TimeComponent,
  ZipComponent,
  AttachmentsAuditHistoryComponent,
  VehicleLookupComponent,
  ProductLookupComponent,
} from './index';
import { HelpTextComponent } from './help-text/help-text.component';
import { CompletionIndicatorComponent } from './completion-indicator/completion-indicator.component';
import { BusinessUnitSearchComponent } from './business-unit-search/business-unit-search.component';
import { BusinessUnitsTreeViewComponent } from './business-units-tree-view/business-units-tree-view.component';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DirectivesModule } from 'app/shared/directives/directives.module';
import { PrintableDocumentsComponent } from './printable-documents/printable-documents.component';
import {
  BusinessUnitAdvancedSearchComponent
} from './business-units-tree-view/business-unit-advanced-search/business-unit-advanced-search.component';
import { BusinessUnitTreeComponent } from './business-units-tree-view/business-unit-tree/business-unit-tree.component';
import { BranchLookupComponent } from './branch-lookup/branch-lookup.component';
import { ContactsSearchComponent } from './contact-lookup/contacts-search/contacts-search.component';
import { ContactDetailsComponent } from './contact-lookup/contact-details/contact-details.component';
import { PolicyComponent } from './policy/policy.component';
import { UkDateComponent } from './uk-date/uk-date.component';
import { UkPhoneComponent } from './uk-phone/uk-phone.component';
import { UkZipComponent } from './uk-zip/uk-zip.component';
import { CloneRecordDialogComponent } from "app/shared/components/clone-record-dialog/clone-record-dialog.component";
import { VehicleSearchComponent } from "app/shared/components/vehicle-lookup/vehicle-search/vehicle-search.component";
import { VehicleDetailsComponent } from "app/shared/components/vehicle-lookup/vehicle-details/vehicle-details.component";

@NgModule ( {
  declarations: [
    ModalComponent,
    ErrorModalComponent,
    NotificationsComponent,
    LoadingIndicatorComponent,
    IconComponent,
    SectionTitleComponent,
    ThreeStateComponent,
    BooleanComponent,
    CodeComponent,
    StringComponent,
    TextComponent,
    EmailComponent,
    InstructionComponent,
    ZipComponent,
    FeinComponent,
    PhoneComponent,
    SsnComponent,
    DollarComponent,
    Float1Component,
    TimeComponent,
    DateComponent,
    UkDateComponent,
    UkPhoneComponent,
    UkZipComponent,
    NumericComponent,
    HelpTextComponent,
    CompletionIndicatorComponent,
    NotificationsComponent,
    BusinessUnitSearchComponent,
    BusinessUnitsTreeViewComponent,
    BusinessUnitDetailsComponent,
    PrintableDocumentsComponent,
    BusinessUnitAdvancedSearchComponent,
    BusinessUnitTreeComponent,
    BranchLookupComponent,
    ContactLookupComponent,
    ContactsSearchComponent,
    ContactDetailsComponent,
    PolicyComponent,
    AuditHistoryComponent,
    AnswersAuditHistoryComponent,
    CloneRecordDialogComponent,
    AttachmentsAuditHistoryComponent,
    VehicleLookupComponent,
    VehicleSearchComponent,
    VehicleDetailsComponent,
    ProductLookupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    InfiniteScrollModule,
    DirectivesModule,
  ],
  exports: [
    ModalComponent,
    LoadingIndicatorComponent,
    ErrorModalComponent,
    IconComponent,
    SectionTitleComponent,
    ThreeStateComponent,
    BooleanComponent,
    CodeComponent,
    StringComponent,
    TextComponent,
    EmailComponent,
    InstructionComponent,
    ZipComponent,
    FeinComponent,
    PhoneComponent,
    SsnComponent,
    DollarComponent,
    Float1Component,
    TimeComponent,
    DateComponent,
    UkDateComponent,
    UkPhoneComponent,
    UkZipComponent,
    NumericComponent,
    HelpTextComponent,
    CompletionIndicatorComponent,
    NotificationsComponent,
    BusinessUnitSearchComponent,
    BusinessUnitsTreeViewComponent,
    BusinessUnitDetailsComponent,
    PrintableDocumentsComponent,
    BranchLookupComponent,
    ContactLookupComponent,
    PolicyComponent,
    AuditHistoryComponent,
    AnswersAuditHistoryComponent,
    CloneRecordDialogComponent,
    AttachmentsAuditHistoryComponent,
    VehicleLookupComponent,
    VehicleSearchComponent,
    VehicleDetailsComponent,
    ProductLookupComponent
  ],
} )
export class ComponentsModule {
}

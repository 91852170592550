<reporter-modal [displayCloseBtn]="false"
                [noFooter]="true"
                [show]="active"
                modalClasses="modal-lg">
  <div role="modal-body"
       class="tree-view-component">
    <div class="reporter-tabs">
      <a class="tab-menu"
         [class.active]="activeTab === 'search'"
         (click)="switchTab('search')">Search</a>
      <a class="tab-menu"
         [class.active]="activeTab === 'tree-view'"
         (click)="switchTab('tree-view')">Tree View</a>

      <div class="close">
        <reporter-icon [cursorPointer]="true"
                       icon="Close"
                       (click)="close(null)"></reporter-icon>
      </div>
    </div>
    <reporter-notifications [clearOnNew]="true"></reporter-notifications>
    <div class="content">
      <div *ngIf="activeTab === 'tree-view'"
           class="tree-view">
        <reporter-business-unit-tree
          [active]="active"
          [buId]="buId"
          [activeDate]="activeDate"
          [reportingCapable]="reportingCapable"
          (selectBU)="selectBu.emit($event)">
        </reporter-business-unit-tree>
      </div>
      <div *ngIf="activeTab === 'search'"
           class="search">
        <reporter-business-unit-advanced-search
          *ngIf="active"
          [activeDate]="activeDate"
          [reportingLocationsOnly]="reportingCapable"
          (selectBU)="selectBu.emit($event)">
        </reporter-business-unit-advanced-search>
      </div>
    </div>
  </div>
</reporter-modal>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { BusinessUnit } from 'app/shared/models/business-unit.model';
import { NotificationService } from 'app/shared/services/notification.service';
import { BusinessUnitsService } from 'app/shared/services/business-units.service';
import { take } from 'rxjs/operators';
import {
  ClientService,
  JurisdictionService,
  UserService
} from 'app/shared/services';
import { Enum } from 'app/shared/models/enum.model';
import { Constants } from 'app/shared/config/constants';

@Component ( {
  selector: 'reporter-business-unit-advanced-search',
  templateUrl: './business-unit-advanced-search.component.html',
  styleUrls: [ './business-unit-advanced-search.component.scss' ]
} )
export class BusinessUnitAdvancedSearchComponent implements OnInit {

  @Input () reportingLocationsOnly = false;
  @Input () activeDate: string;
  @Output () selectBU = new EventEmitter<BusinessUnit> ();

  advancedSearchOpen = true;
  searchModel: any = {};
  searchResults: BusinessUnit[];
  jurisdictions: Enum[];
  pageNumber = 1;
  buDetails: BusinessUnit;
  timeOut;
  selectedOnHover = false;
  inFocus: BusinessUnit;
  totalRecords = 0;
  totalPages = 0;
  buReferenceNumberPrompt: string;
  systemClientSelected: boolean;

  constructor(
    private notificationService: NotificationService,
    private businessUnitsService: BusinessUnitsService,
    private jurisdictionService: JurisdictionService,
    private clientService: ClientService,
    private userService: UserService
  ) {
    this.systemClientSelected = this.userService.currentClient?.clientNumber?.toUpperCase() === 'SYSTEM';
  }

  ngOnInit() {
    this.jurisdictionService.allByCountry ()
      .pipe ( take ( 1 ) )
      .subscribe (
        jurisdictions => this.jurisdictions = jurisdictions
      );
    this.searchModel.reportingLocationsOnly = !!this.reportingLocationsOnly;
    if ( this.activeDate ) {
      this.searchModel.activeDate = this.activeDate;
    }
    this.buReferenceNumberPrompt =
      this.clientService.buTextSettingsByKey ( 'BU_REFERENCE_NUMBER_PROMPT' ) || Constants.buReferenceNumberPrompt;
  }

  submit() {
    this.notificationService.clearAll ();
    this.searchResults = [];
    this.pageNumber = 1;

    this.businessUnitsService.businessUnitsSearch ( this.searchModel )
      .pipe ( take ( 1 ) )
      .subscribe ( results => {
        this.searchResults = results.records;
        this.totalRecords = results.totalRecords;
        this.totalPages = results.totalPages;
        if ( !this.searchResults?.length ) {
          this.advancedSearchOpen = true;
          this.notificationService.addNotification ( 'warning', 'No records found.' );
        } else {
          this.advancedSearchOpen = false;
        }
      } );
  }

  loadNextPage() {
    this.pageNumber++;
    this.businessUnitsService.businessUnitsSearch ( this.searchModel, this.pageNumber )
      .pipe ( take ( 1 ) )
      .subscribe ( results => {
        if ( results?.records?.length ) {
          this.searchResults = [ ...this.searchResults, ...results.records ];
        }
      } );
  }

  async getBUDetails( bu: BusinessUnit ) {
    this.selectedOnHover = false;
    if ( bu?.id === this.buDetails?.id ) {
      this.buDetails = undefined;
      this.inFocus = undefined;
    } else {
      if ( bu?.id.toUpperCase() !== 'SYSTEM' ) {
        this.inFocus = bu;
        this.buDetails = undefined;
        this.buDetails = await this.businessUnitsService.getBUDetails ( bu.id )
          .toPromise ();
      }
    }
  }

  mouseOver( bu: BusinessUnit ) {
    if ( bu?.id !== this.buDetails?.id ) {
      if ( this.timeOut ) {
        clearTimeout ( this.timeOut );
      }
      this.timeOut = setTimeout ( async () => {
        await this.getBUDetails ( bu );
        this.selectedOnHover = true;
      }, 2000 );
    }
  }

  unsetTimeOut( bu: BusinessUnit ) {
    if ( this.timeOut ) {
      clearTimeout ( this.timeOut );
    }
    if ( this.buDetails && bu?.id === this.buDetails?.id && this.selectedOnHover ) {
      this.selectedOnHover = false;
      this.buDetails = undefined;
      this.inFocus = undefined;
    }
  }

  emitSelected( bu: BusinessUnit ) {
    this.selectBU.emit ( bu );
    this.inFocus = undefined;
  }

  resetForm() {
    this.searchModel = {};
    this.searchResults = undefined;
    if ( this.reportingLocationsOnly ) {
      this.searchModel.reportingLocationsOnly = true;
    }
    if ( this.activeDate ) {
      this.searchModel.activeDate = this.activeDate;
    }
  }
}

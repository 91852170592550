<input #field
       type="hidden"
       [ngModel]="innerValue"
       (ngModelChange)="writeValue($event)">
<div class="input-group">
  <input type="text"
         class="form-control"
         [(ngModel)]="innerValue"
         [disabled]="isLinked"
         *ngIf="!isLinked"
         (ngModelChange)="writeValue($event)"
         [required]="required">
  <input *ngIf="innerValue && isLinked"
         type="text"
         class="form-control"
         [ngModel]="selected?.number"
         disabled
         [required]="required">
  <div class="input-group-append">
    <button *ngIf="isLinked"
            type="button"
            class="clear-search"
            (click)="clear()">
      <i class="fas fa-times-circle"></i>
    </button>
    <button *ngIf="!isLinked"
            class="btn btn-action"
            (click)="search()"
            type="button"
            [disabled]="!innerValue">
      <reporter-icon icon="Search"></reporter-icon>
    </button>
  </div>
  <div class="dropdown-menu w-100 scrollable"
       [class.show]="products?.length > 1 && !isLinked">
    <button *ngFor="let product of products; let index = index"
            class="dropdown-item"
            type="button"
            (click)="select(product)">{{ product.number }}
    </button>
  </div>
  <div *ngIf="products?.length === 0 || showError" class="w-100 alert alert-warning">
    Product Not Found!
  </div>
</div>

